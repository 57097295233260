import { Amplify, Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function csvToArr(stringVal, splitter) {
    const [keys, ...rest] = stringVal
      .trim()
      .split("\n")
      .map((item) => item.split(splitter));
    const formedArr = rest.map((item) => {
      const object = {};
      keys.forEach((key, index) => (object[key] = item.at(index)));
      return object;
    });
    return formedArr;
  }

async function getEvents(){
   

        const result = await Storage.get(`rugbyecclesiaevents.csv`, { contentType: 'text/plain', download: true });

            let events = [];

            // data.Body is a Blob
            result.Body.text().then((string) => {

                events = csvToArr(string, ',');
                console.log(events);
                const TableOfEvents = document.getElementById("TableOfEvents");

                const result = events.map(evt => {
            
                    if (evt.regOpen == 'true'){
                        var link = evt.regLink;
                        var linkColour = "blue";
                    }else{
                        var link = "#";
                        var linkColour = "";
                    };
    
                    var buttonText = evt.buttonText || "Register";
    
                    const d = new Date(evt.date).toLocaleDateString("en-GB");
    
                    TableOfEvents.innerHTML += `<tr>
                                                    <td>${evt.name}</td>
                                                    <td>${d}</td>
                                                    <td>${evt.description}</td>
                                                    <td><a class="ui ${linkColour} button" href="${link}" target="_blank">${buttonText}</a></td>
                                                </tr>`;
        
                    console.log(evt);
    
                    
                });
            });

     
        
}

(async () => {
    await getEvents()
  })()


